import React from 'react'
import MediaQuery from 'react-responsive'
import coupon_menu from './../../../images/create-coupon/Coupon_menu.webp'
import coupon_menu_mobile from './../../../images/create-coupon/Coupon_menu_m.webp'
import create_coupon from './../../../images/create-coupon/Create_coupon.webp'
import create_coupon_mobile from './../../../images/create-coupon/Create_coupon_m.webp'
import coupon_term from './../../../images/create-coupon/Coupon_term.webp'
import coupon_term_mobile from './../../../images/create-coupon/Coupon_term_m.webp'
import coupon_detail from './../../../images/create-coupon/Coupon_detail.webp'
import coupon_detail_mobile from './../../../images/create-coupon/Coupon_detail_m.webp'
import coupon_detail_2 from './../../../images/create-coupon/Coupon_detail_2.webp'
import coupon_detail_mobile_2 from './../../../images/create-coupon/Coupon_detail_m_2.webp'
import coupon_detail_3 from './../../../images/create-coupon/Coupon_detail_3.webp'
import coupon_detail_mobile_3 from './../../../images/create-coupon/Coupon_detail_m_3.webp'
import coupon_detail_4 from './../../../images/create-coupon/Coupon_detail_4.webp'
import coupon_detail_mobile_4 from './../../../images/create-coupon/Coupon_detail_m_4.webp'
import coupon_detail_5 from './../../../images/create-coupon/Coupon_detail_5.webp'
import coupon_detail_mobile_5 from './../../../images/create-coupon/Coupon_detail_m_5.webp'
import coupon_detail_6 from './../../../images/create-coupon/Coupon_detail_6.webp'
import coupon_detail_mobile_6 from './../../../images/create-coupon/Coupon_detail_m_6.webp'
import coupon_detail_7 from './../../../images/create-coupon/Coupon_detail_7.webp'
import coupon_detail_mobile_7 from './../../../images/create-coupon/Coupon_detail_m_7.webp'
import coupon_detail_8 from './../../../images/create-coupon/Coupon_detail_8.webp'
import coupon_detail_mobile_8 from './../../../images/create-coupon/Coupon_detail_m_8.webp'
import coupon_detail_9 from './../../../images/create-coupon/Coupon_detail_9.webp'
import coupon_detail_mobile_9 from './../../../images/create-coupon/Coupon_detail_m_9.webp'
import coupon_detail_10 from './../../../images/create-coupon/Coupon_detail_10.webp'
import coupon_detail_mobile_10 from './../../../images/create-coupon/Coupon_detail_m_10.webp'
import coupon_detail_11 from './../../../images/create-coupon/Coupon_detail_11.webp'
import coupon_detail_mobile_11 from './../../../images/create-coupon/Coupon_detail_m_11.webp'
export default function createCoupon(props) {
  return (
    <div className="fade-in" lang="th">
      {/* Start Desktop size part */}
      <MediaQuery minWidth={1024}>
        <div className="container-1420 m-auto">
          <div className="h-16"></div>
          <div
            className="text-body-header font-bold mt-16
                        max:text-size-55 2xl:text-size-50 xl:text-size-45 lg:text-size-40"
          >
            วิธีการสร้างคูปอง (coupon)
          </div>
          <br />
          <br />
          <p>
            ในการเริ่มใช้งานคูปองคุณต้องทำการสร้างคูปอง (coupon)
            เพื่อกำหนดเงื่อนไขในการใช้งาน โดยสามารถทำได้ตามขั้นตอนดังต่อไปนี้
          </p>
          <br />
          <ul className="list-decimal">
            <li>
              <span>จากหน้าหลักเลือกที่ “คูปอง”</span>
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_menu"
              src={coupon_menu}
            />
            <br />
            <br />
            <li>คลิกที่ “เพิ่ม” เพื่อเริ่มสร้างคูปอง</li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="create_coupon"
              src={create_coupon}
            />
            <br />
            <br />
            <li>
              ระบบจะแสดงเงื่อนไขการใช้งานกด “ยอมรับ” เพื่อสร้างคูปอง
              <br />
              <br />
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_term"
                src={coupon_term}
              />
              <br />
              <p>
                *** กรณีร้านค้ายังไม่ได้ระบุข้อมูลบัญชีรับเงิน ให้กดคลิกที่
                "จัดการร้านค้า" เพื่อทำการเพิ่มข้อมูลบัญชีรับเงิน
                โดยจะสามารถทำได้เฉพาะเจ้าของร้านเท่านั้น
              </p>
            </li>
            <br />
            <li>
              <p>จากนั้นเพิ่มคูปองโดยมีข้อมูลที่ต้องทำการกรอกมีดังนี้</p>
              {/* <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail"
                src={coupon_detail}
              />
              <br /> */}
              <p>ชื่อคูปองภาษาไทย : กรอกชื่อคูปองที่ต้องการแสดง (ภาษาไทย)</p>
              <p>ชื่อคูปองภาษาอังกฤษ : กรอกชื่อคูปองที่ต้องการแสดง (อังกฤษ)</p>
              <p>บริการของคูปอง : เลือกบริการของทางร้าน</p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_2"
                src={coupon_detail_2}
              />
              <br />
              <br />
              <p>จำนวนคูปอง : กรอกจำนวนคูปองที่ต้องการขาย (ขั้นต่ำ 20 ใบ)</p>
              <p>
                ราคาคูปอง : กำหนดราคาจำหน่ายคูปอง ระหว่าง 50 ถึง 1000 บาท/ใบ
                (รวมค่าธรรมเนียมร้อยละ 10 ต่อใบ)
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_5"
                src={coupon_detail_5}
              />
              <br />
              <p>
                ช่วงเวลาการขายคูปอง : เลือกวันที่เริ่มจำหน่าย และ
                วันสิ้นสุดของการจำหน่ายคูปอง
                (วันที่เริ่มจำหน่ายต้องมากกว่าวันที่ขออนุมัติ อย่างน้อย 7 วัน)
              </p>
              <br />
              <div className="grid grid-cols-5">
                <img
                  className="mx-auto col-span-2"
                  alt="coupon_detail_3"
                  src={coupon_detail_3}
                />
                <div className="col-span-1"></div>
                <img
                  className="mx-auto col-span-2"
                  alt="coupon_detail_4"
                  src={coupon_detail_4}
                />
              </div>
              <br />
              <p>
                สามารถนัดหมายได้หลังจากซื้อคูปองไปแล้ว :
                กำหนดจำนวนวันที่สามารถทำนัดหมายได้หลังจากซื้อคูปอง
                โดยจำนวนวันต้องอยู่ระหว่าง 1-30 วัน
              </p>
              <p>
                รายละเอียดคูปองภาษาไทย : กำหนดเงื่อนไขในการใช้คูปองสำหรับลูกค้า
                (ภาษาไทย)
              </p>
              <p>
                รายละเอียดคูปองภาษาอังกฤษ :
                กำหนดเงื่อนไขในการใช้คูปองสำหรับลูกค้า (ภาษาอังกฤษ)
              </p>
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_6"
              src={coupon_detail_6}
            />
            <br />
            <li>
              หลังจากกรอกข้อมูลต่างๆให้กับคูปองครบถ้วนแล้วให้คุณทำการคลิกที่
              “สร้าง” เพื่อสร้างคูปองดังกล่าว
            </li>
            <li>
              เมื่อกดปุ่มสร้างเรียบร้อยแล้วจะสามารถแนบรูปภาพของคูปอง จากนั้นกด
              "บันทึก"
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_7"
              src={coupon_detail_7}
            />
            <br />
            <li>
              ถึงขั้นตอนนี้คุณสามารถส่งคูปองเพื่อให้ทาง AAppoint
              ตรวจสอบและอนุมัติการขายคูปองได้โดยกดที่ "ส่งเพื่อขออนุมัติ"
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_8"
              src={coupon_detail_8}
            />
            <br />
            <li>
              จากนั้นระบบจะแสดงข้อความแจ้งเตือนยืนยันการส่งขออนุมัติกด "ตกลง"
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_9"
              src={coupon_detail_9}
            />
            <br />
            <li>
              เมื่อกดยืนยันคูปองจะถูกส่งให้ทาง AAppoint
              พิจารณาโดยที่คูปองจะอยู่ในสถานะ "รอการอนุมัติ"
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_10"
              src={coupon_detail_10}
            />
            <br />
            <li>
              เมื่อต้องการกลับหน้าสร้างคูปองคลิกที่เมนูคูปอง
              จะแสดงรายการคูปองที่ทางร้านสร้าง และสามารถติดตามสถานะการอนุมัติได้
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_11"
              src={coupon_detail_11}
            />
            <br />
            <li>
              เมื่อคูปองได้รับอนุมัติแล้วทางร้านค้าจึงจะสามารถขายคูปองให้กับลูกค้าได้
            </li>
          </ul>
        </div>
      </MediaQuery>
      {/* End Desktop size part */}
      {/* Start Mobile size part */}
      <MediaQuery maxWidth={1023}>
        <div className="h-32 md:h-48 sm:h-40"></div>
        <div className="container-mobile-content m-auto">
          <div className="text-body-header font-bold md:text-size-20 sm:text-size-18">
            วิธีการสร้างคูปอง
          </div>
          <br />
          <br />
          <p>
            ในการเริ่มใช้งานคูปองคุณต้องทำการสร้างคูปอง
            เพื่อกำหนดเงื่อนไขในการใช้งาน โดยขั้นตอนการสร้างคูปองมีดังนี้
          </p>
          <br />
          <ul className="list-decimal">
            <li>จากหน้าหลัก กดที่เมนูคูปอง</li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_menu_mobile"
              src={coupon_menu_mobile}
            />
            <br />
            <li>จากหน้ารายการคูปอง คลิกที่ “เพิ่มคูปอง ”</li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="create_coupon_mobile"
              src={create_coupon_mobile}
            />
            <br />
            <li>
              ระบบจะแสดงเงื่อนไขการใช้งานกด “ยอมรับ” เพื่อไปยังหน้าสร้างคูปอง
              <p>
                *** กรณีร้านค้ายังไม่ได้ระบุข้อมูลบัญชีรับเงิน ให้กดปุ่ม
                "จัดการร้านค้า" เพื่อทำการเพิ่มข้อมูลบัญชีรับเงิน
                โดยจะสามารถทำได้เฉพาะเจ้าของร้านเท่านั้น
              </p>
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_term_mobile"
              src={coupon_term_mobile}
            />
            <br />
            <li>
              <p>
                ระบบจะแสดงหน้าจอการเพิ่มคูปอง
                ซึ่งข้อมูลที่จำเป็นต้องระบุถ้าไม่ระบุจะแจ้งเตือนเป็นตัวหนังสือสีแดง
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile"
                src={coupon_detail_mobile}
              />
              <br />
              <p>โดยข้อมูลที่ต้องทำการกรอกมีดังนี้</p>
              <p>ชื่อคูปองภาษาไทย : กรอกชื่อคูปองที่ต้องการแสดง (ภาษาไทย)</p>
              <p>ชื่อคูปองภาษาอังกฤษ : กรอกชื่อคูปองที่ต้องการแสดง (อังกฤษ)</p>
              <p>
                บริการของคูปอง : เลือกประเภทการบริการ
                โดยเลือกจากรายการที่ร้านได้กำหนดไว้ดังนี้
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_2"
                src={coupon_detail_mobile_2}
              />
              <br />
              <p>จำนวนคูปอง : กรอกจำนวนคูปองที่ต้องการขาย (ขั้นต่ำ 20 ใบ)</p>
              <p>
                ราคาคูปอง : กำหนดราคาจำหน่ายคูปอง ระหว่าง 50 ถึง 1000 บาท/ใบ
                (รวมค่าธรรมเนียมร้อยละ 8 /ใบ)
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_5"
                src={coupon_detail_mobile_5}
              />
              <br />
              <p>
                ช่วงเวลาการขายคูปอง : เลือกวันที่เริ่มจำหน่ายคูปอง และ
                วันสิ้นสุดการจำหน่าย
                (วันที่เริ่มจำหน่ายต้องมากกว่าวันที่ขออนุมัติ อย่างน้อย 7 วัน)
              </p>
              <br />
              <p>เลือกวันที่เริ่มจำหน่ายคูปอง</p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_3"
                src={coupon_detail_mobile_3}
              />
              <br />
              <p>จากนั้นเลือกวันสิ้นสุดการจำหน่าย</p>
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_4"
                src={coupon_detail_mobile_4}
              />
              <br />
              <p>
                สามารถนัดหมายได้หลังจากซื้อคูปองไปแล้ว :
                กำหนดจำนวนวันที่สามารถทำนัดหมายได้หลังจากซื้อคูปอง
                โดยจำนวนวันต้องอยู่ระหว่าง 1-30 วัน
              </p>
              <p>
                รายละเอียดคูปองภาษาไทย : กำหนดเงื่อนไขในการใช้คูปองสำหรับลูกค้า
                (ภาษาไทย)
              </p>
              <p>
                รายละเอียดคูปองภาษาอังกฤษ :
                กำหนดเงื่อนไขในการใช้คูปองสำหรับลูกค้า (ภาษาอังกฤษ)
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_6"
                src={coupon_detail_mobile_6}
              />
              <br />
            </li>
            <li>
              หลังจากกรอกข้อมูลต่างๆให้กับคูปองครบถ้วนแล้วให้คุณทำการคลิกที่
              “สร้าง” เพื่อสร้างคูปองดังกล่าว
            </li>
            <li>
              เมื่อกดปุ่มสร้างเรียบร้อยแล้วจะสามารถแนบรูปภาพของคูปอง จากนั้นกด
              "บันทึก"
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_7"
              src={coupon_detail_mobile_7}
            />
            <br />
            <li>
              ถึงขั้นตอนนี้คุณสามารถส่งคูปองเพื่อให้ทาง AAppoint
              ตรวจสอบและอนุมัติการขายคูปองได้โดยกดที่ "ส่งเพื่อขออนุมัติ"
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_8"
              src={coupon_detail_mobile_8}
            />
            <br />
            <li>
              จากนั้นระบบจะแสดงข้อความแจ้งเตือนยืนยันการส่งขออนุมัติกด "ตกลง"
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_9"
              src={coupon_detail_mobile_9}
            />
            <br />
            <li>
              เมื่อกดยืนยันคูปองจะถูกส่งให้ทาง AAppoint
              พิจารณาโดยที่คูปองจะอยู่ในสถานะ "รอการอนุมัติ"
            </li>
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_10"
              src={coupon_detail_mobile_10}
            />
            <br />
            <li>
              เมื่อต้องการกลับหน้าสร้างคูปองคลิกที่เมนูคูปอง
              ระบบจะแสดงรายการคูปองที่ทางร้านสร้าง
              และสามารถติดตามสถานะการอนุมติได้
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_11"
              src={coupon_detail_mobile_11}
            />
            <br />
            <li>
              เมื่อคูปองได้รับอนุมัติแล้วทางร้านค้าจึงจะสามารถขายคูปองให้กับลูกค้าได้
            </li>
          </ul>
        </div>
      </MediaQuery>
      {/* End Mobile size part */}
    </div>
  )
}
