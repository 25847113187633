import React, { Component } from 'react'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import appAction from '../../../state/app/actions'
import View from '../../../components/presentation/faq/th-create-coupon'

class createCoupon extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
        this.toggleLang = this.toggleLang.bind(this)
    }
    toggleLang = () => {
        if (this.props.app.lang === 'EN') {
            this.props.onChangeLanguage('TH')
        } else {
            this.props.onChangeLanguage('EN')
        }
        return this.props.app.lang
    }
    render() {
        return (
            <Layout
                locale={this.props.app.locale}
                lang={'TH'}
                toggleLang={this.toggleLang}
            >
                <SEO 
                    title="How to create the coupon"
                    description="Aappoint is the best service marketplace platform"
                />
                <View
                    locale={this.props.app.locale}
                    lang={'TH'}
                />
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onChangeLanguage: appAction.onChangeLanguage
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(createCoupon)
